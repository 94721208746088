import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.attribute != null ? _c('div', [_c('div', {
    staticClass: "d-flex"
  }, [_c('Input', {
    attrs: {
      "value": _vm.attribute.label,
      "dense": "",
      "label": _vm.id,
      "placeholder": "ラベル名"
    },
    on: {
      "focus": function focus($event) {
        return _setup.emits('focus', $event);
      },
      "blur": function blur($event) {
        return _setup.emits('blur', $event);
      },
      "input": function input($event) {
        return _setup.emits('update:attribute', _objectSpread(_objectSpread({}, _vm.attribute), {}, {
          label: $event
        }));
      }
    }
  }), _vm._v(" "), _c('div', [_c('SmallLabel', {
    staticClass: "mb-1",
    attrs: {
      "label": "text-anchor"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "tw-flex"
  }, [_c('button', {
    staticClass: "tw-btn tw-btn-icon-small tw-btn-icon-info -tw-mr-[1px] tw-border tw-border-solid tw-border-border-input disabled:tw-border-border tw-rounded-s-md tw-rounded-e-none",
    class: {
      '!tw-bg-disabled': _vm.attribute.textAnchor == 'start'
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _setup.emits('update:attribute', _objectSpread(_objectSpread({}, _vm.attribute), {}, {
          textAnchor: _vm.attribute.textAnchor == 'start' ? undefined : 'start'
        }));
      }
    }
  }, [_c('mdi-format-align-left', {
    staticClass: "tw-size-4"
  })], 1), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-icon-small tw-btn-icon-info tw-border tw-border-solid tw-border-border-input disabled:tw-border-border tw-rounded-none",
    class: {
      '!tw-bg-disabled': _vm.attribute.textAnchor == 'middle'
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _setup.emits('update:attribute', _objectSpread(_objectSpread({}, _vm.attribute), {}, {
          textAnchor: _vm.attribute.textAnchor == 'middle' ? undefined : 'middle'
        }));
      }
    }
  }, [_c('mdi-format-align-center', {
    staticClass: "tw-size-4"
  })], 1), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-icon-small tw-btn-icon-info -tw-ml-[1px] tw-border tw-border-solid tw-border-border-input disabled:tw-border-border tw-rounded-s-none tw-rounded-e-md",
    class: {
      '!tw-bg-disabled': _vm.attribute.textAnchor == 'end'
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _setup.emits('update:attribute', _objectSpread(_objectSpread({}, _vm.attribute), {}, {
          textAnchor: _vm.attribute.textAnchor == 'end' ? undefined : 'end'
        }));
      }
    }
  }, [_c('mdi-format-align-right', {
    staticClass: "tw-size-4"
  })], 1)])], 1), _vm._v(" "), _c('Input', {
    staticStyle: {
      "max-width": "5rem"
    },
    attrs: {
      "value": _vm.attribute.lineHeight,
      "label": "line-height",
      "type": "combobox",
      "dense": "",
      "items": ['1.25em', '1.5em', '1.75em', '2em'],
      "placeholder": "",
      "append-icon": "",
      "clearable": false
    },
    on: {
      "input": function input($event) {
        return _setup.emits('update:attribute', _objectSpread(_objectSpread({}, _vm.attribute), {}, {
          lineHeight: $event
        }));
      }
    }
  })], 1), _vm._v(" "), _c('Input', {
    attrs: {
      "value": _vm.attribute.placeholder,
      "dense": "",
      "placeholder": "プレースホルダー文言",
      "type": "multiLine",
      "rows": "1"
    },
    on: {
      "input": function input($event) {
        return _setup.emits('update:attribute', _objectSpread(_objectSpread({}, _vm.attribute), {}, {
          placeholder: $event
        }));
      },
      "contextmenu": function contextmenu($event) {
        $event.preventDefault();
        return _setup.emits('contextmenu', $event);
      }
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "d-flex"
  }, [_c('Input', {
    attrs: {
      "value": _vm.attribute.help,
      "dense": "",
      "placeholder": "ヘルプ文言 or URL"
    },
    on: {
      "input": function input($event) {
        return _setup.emits('update:attribute', _objectSpread(_objectSpread({}, _vm.attribute), {}, {
          help: $event
        }));
      }
    }
  }), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-icon-small tw-btn-icon-info ml-4",
    attrs: {
      "type": "button",
      "disabled": _vm.attribute.readOnly
    },
    on: {
      "click": function click($event) {
        return _setup.emits('update:attribute', _objectSpread(_objectSpread({}, _vm.attribute), {}, {
          proofread: !_vm.attribute.proofread
        }));
      }
    }
  }, [_vm.attribute.proofread ? _c('mdi-robot', {
    staticClass: "tw-size-6"
  }) : _c('mdi-robot-dead-outline', {
    staticClass: "tw-size-6"
  })], 1)], 1), _vm._v(" "), _c('Input', {
    attrs: {
      "value": _vm.attribute.defaultText,
      "dense": "",
      "placeholder": _vm.attribute.readOnly ? '固定文言' : '初期文言',
      "type": "multiLine",
      "rows": "1"
    },
    on: {
      "input": function input($event) {
        return _setup.emits('update:attribute', _objectSpread(_objectSpread({}, _vm.attribute), {}, {
          defaultText: $event
        }));
      },
      "contextmenu": function contextmenu($event) {
        $event.preventDefault();
        return _setup.emits('contextmenu', $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('button', {
          staticClass: "tw-btn tw-btn-icon-x-small tw-btn-icon-info ml-2",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              return _setup.emits('update:attribute', _objectSpread(_objectSpread({}, _vm.attribute), {}, {
                readOnly: !_vm.attribute.readOnly,
                proofread: !_vm.attribute.readOnly ? false : _vm.attribute.proofread
              }));
            }
          }
        }, [_vm.attribute.readOnly ? _c('mdi-lock', {
          staticClass: "tw-size-6"
        }) : _c('mdi-lock-open-outline', {
          staticClass: "tw-size-6"
        })], 1)];
      },
      proxy: true
    }], null, false, 3607510631)
  })], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };