import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VContainer, {
    staticClass: "print-editor flex-column justify-center white",
    class: {
      'fill-height': !_setup.svg
    },
    attrs: {
      "fluid": ""
    }
  }, [!_setup.svg ? _c('FileDropArea', {
    staticClass: "tw-bg-surface tw-rounded-lg tw-max-w-full tw-border tw-flex tw-flex-col tw-items-center tw-justify-center tw-min-w-[400px] tw-min-h-[400px] tw-p-6",
    attrs: {
      "dragging-class": "!tw-bg-background",
      "accept": "image/svg+xml"
    },
    on: {
      "file": _setup.onFilePicked
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c(VSpacer), _vm._v(" "), _c('p', [_vm._v("ここにSVGファイルをドロップ")]), _vm._v(" "), _c('button', _vm._g({
          staticClass: "tw-btn tw-btn-fill-primary",
          attrs: {
            "type": "button"
          },
          domProps: {
            "textContent": _vm._s("\u30D5\u30A1\u30A4\u30EB\u3092\u9078\u629E")
          }
        }, on)), _vm._v(" "), _c(VSpacer), _vm._v(" "), _setup.hasSaveData ? _c('TextButton', {
          staticClass: "mt-4",
          attrs: {
            "value": "保存したデータで再開する",
            "color": "primary",
            "small": "",
            "depressed": ""
          },
          on: {
            "click": function click($event) {
              return _setup.restoreSnapshot(false);
            }
          }
        }) : _vm._e()];
      }
    }], null, false, 2458293498)
  }) : [_c('SplitView', {
    attrs: {
      "size": "66"
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c(_setup.ContextMenu, {
          ref: "menuRef",
          attrs: {
            "icon-color": "primary"
          }
        }), _vm._v(" "), _c('div', {
          staticClass: "p-relative"
        }, [_c('div', {
          key: _setup.componentKey,
          ref: "rootRef",
          staticClass: "svg-container",
          class: {
            outlined: !_setup.hideOutline
          },
          domProps: {
            "innerHTML": _vm._s(_setup.svg)
          },
          on: {
            "contextmenu": function contextmenu($event) {
              $event.preventDefault();
              return _setup.onContextmenu.apply(null, arguments);
            },
            "mousedown": function mousedown($event) {
              $event.preventDefault();
              return _setup.onMouseDown.apply(null, arguments);
            }
          }
        }), _vm._v(" "), _c(_setup.ToolsEditorHelp)], 1), _vm._v(" "), _c(VCheckbox, {
          attrs: {
            "label": "アウトラインを表示しない"
          },
          model: {
            value: _setup.hideOutline,
            callback: function callback($$v) {
              _setup.hideOutline = $$v;
            },
            expression: "hideOutline"
          }
        })];
      },
      proxy: true
    }, {
      key: "second",
      fn: function fn() {
        return [_c(_setup.ContextMenu, {
          ref: "textMenuRef",
          attrs: {
            "items": _setup.textMenuItems,
            "icon-color": "secondary-text"
          }
        }), _vm._v(" "), _c('SectionSubTitle', [_vm._v("textタグの属性を設定")]), _vm._v(" "), _vm._l(_setup.textAttrs, function (value, id) {
          return _c(_setup.ToolsEditorTextInput, {
            key: id,
            staticClass: "mb-2",
            attrs: {
              "id": typeof id === 'string' ? id : "".concat(id),
              "attribute": value
            },
            on: {
              "update:attribute": function updateAttribute($event) {
                _setup.textAttrs[id] = $event;
              },
              "focus": function focus($event) {
                return _setup.setFocusOutline(id);
              },
              "blur": function blur($event) {
                return _setup.removeFocusOutline();
              },
              "contextmenu": function contextmenu($event) {
                var _setup$textMenuRef;
                $event.preventDefault();
                (_setup$textMenuRef = _setup.textMenuRef) === null || _setup$textMenuRef === void 0 ? void 0 : _setup$textMenuRef.show($event);
              }
            }
          });
        }), _vm._v(" "), _c('SectionSubTitle', {
          staticClass: "mt-6"
        }, [_vm._v("imageタグの属性を設定")]), _vm._v(" "), _c(_setup.ToolsEditorImageSetting, {
          attrs: {
            "images": _setup.images,
            "image-sizes": _setup.imageSizes,
            "option": _setup.option
          },
          on: {
            "update:images": function updateImages($event) {
              _setup.images = $event;
            },
            "update:imageSizes": function updateImageSizes($event) {
              _setup.imageSizes = $event;
            },
            "update:image-sizes": function updateImageSizes($event) {
              _setup.imageSizes = $event;
            },
            "update:option": function updateOption($event) {
              _setup.option = $event;
            }
          }
        })];
      },
      proxy: true
    }])
  }), _vm._v(" "), _c('div', {
    staticClass: "d-flex justify-center py-6"
  }, [_c('button', {
    staticClass: "tw-btn tw-btn-outline-secondary mr-4",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _setup.previewSvgText(false);
      }
    }
  }, [_c('mdi-arrow-down-bold-outline', {
    staticClass: "tw-size-4"
  }), _vm._v("\n        プレビュー\n      ")], 1), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-outline-secondary mr-4",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _setup.previewSvgText(true);
      }
    }
  }, [_c('mdi-arrow-down-bold-outline', {
    staticClass: "tw-size-4"
  }), _vm._v("\n        プレビュー(初期文言反映)\n      ")], 1), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-fill-primary mr-4",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _setup.saveSnapshot
    }
  }, [_c('mdi-content-save', {
    staticClass: "tw-size-4"
  }), _vm._v("\n        一時的に保存\n      ")], 1), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-fill-secondary mr-4",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _setup.restoreSnapshot
    }
  }, [_c('mdi-reload', {
    staticClass: "tw-size-4"
  }), _vm._v("\n        保存した状態に戻す\n      ")], 1)]), _vm._v(" "), _setup.dist ? [_c('TouchTextViewer', {
    attrs: {
      "svg": _setup.dist,
      "shipping-targets": [{
        contact: _setup.TEST_CONTACT_DATA
      }],
      "qr-url": "http://example.com/qr",
      "gift-url": "http://example.com/gift",
      "logo": _setup.LOGO_SAMPLE,
      "edit": "",
      "check-placeholder": ""
    },
    on: {
      "contextmenu": function contextmenu($event) {
        $event.preventDefault();
        return _vm.$toast.error('プレビューでテキストメニュー非対応です');
      }
    },
    model: {
      value: _setup.texts,
      callback: function callback($$v) {
        _setup.texts = $$v;
      },
      expression: "texts"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "d-flex justify-center py-6"
  }, [_c('button', {
    staticClass: "tw-btn tw-btn-fill-secondary mr-4",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _setup.copySvgText
    }
  }, [_c('mdi-content-copy', {
    staticClass: "tw-size-4"
  }), _vm._v("\n          クリップボードにコピー\n        ")], 1), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-fill-primary mr-4",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _setup.downloadSvg
    }
  }, [_c('mdi-download', {
    staticClass: "tw-size-4"
  }), _vm._v("\n          ダウンロード\n        ")], 1)])] : _vm._e()]], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };