import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('FloatingTooltip', {
    attrs: {
      "bottom": "",
      "hoverable": "",
      "color": "black",
      "content-class": "white--text"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c(VIcon, _vm._g(_vm._b({
          staticClass: "p-absolute ma-2",
          staticStyle: {
            "top": "0",
            "right": "0"
          },
          attrs: {
            "color": "primary",
            "small": ""
          }
        }, 'v-icon', attrs, false), on), [_vm._v("\n      mdi-help-circle\n    ")])];
      }
    }])
  }, [_vm._v(" "), _c('div', [_c('SectionSubTitle', [_vm._v("使い方")]), _vm._v(" "), _c('div', {
    staticClass: "text-body-1 mt-1"
  }, [_vm._v("テキスト枠")]), _vm._v(" "), _c('div', {
    staticClass: "text-body-2 mt-1"
  }, [_vm._v("・ Shift+drag → サイズを変更")]), _vm._v(" "), _c('div', {
    staticClass: "text-caption ml-4"
  }, [_vm._v("※左寄せ/中央寄せの場合は右下への拡大")]), _vm._v(" "), _c('div', {
    staticClass: "text-caption ml-4"
  }, [_vm._v("※右寄せの場合は左下への拡大")]), _vm._v(" "), _c('div', {
    staticClass: "text-caption ml-4"
  }, [_vm._v("\n      ※枠の拡大後にテキストが配置指定に従って移動します\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "text-body-2 mt-1"
  }, [_vm._v("・ Alt+drag → 位置を変更")]), _vm._v(" "), _c('div', {
    staticClass: "text-caption ml-4"
  }, [_vm._v("\n      ※枠を移動するとテキストも追従して移動します\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "text-body-2 mt-1"
  }, [_vm._v("・ 右クリック → コンテキストメニュー")]), _vm._v(" "), _c('div', {
    staticClass: "text-caption ml-4"
  }, [_vm._v("- 入力可否を変更")]), _vm._v(" "), _c('div', {
    staticClass: "text-caption ml-4"
  }, [_vm._v("- 文字回り込みエリアの追加")]), _vm._v(" "), _c('div', {
    staticClass: "text-caption ml-4"
  }, [_vm._v("- テキストの配置を指定")]), _vm._v(" "), _c('div', {
    staticClass: "text-caption ml-4"
  }, [_vm._v("- 印刷対象外の設定")]), _vm._v(" "), _c('div', {
    staticClass: "text-caption ml-4"
  }, [_vm._v("- 固定文言の設定")]), _vm._v(" "), _c('div', {
    staticClass: "text-body-1 mt-1"
  }, [_vm._v("画像")]), _vm._v(" "), _c('div', {
    staticClass: "text-body-2 mt-1"
  }, [_vm._v("・ Shift+drag → サイズを変更")]), _vm._v(" "), _c('div', {
    staticClass: "text-caption ml-4"
  }, [_vm._v("\n      ※サイズ変更しようとすると画像のサイズが強制的に「枠に収まるように」になります\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "text-body-2 mt-1"
  }, [_vm._v("・ Alt+drag → 位置を変更")]), _vm._v(" "), _c('div', {
    staticClass: "text-caption ml-4"
  }, [_vm._v("- 入力可否を変更")]), _vm._v(" "), _c('div', {
    staticClass: "text-body-2 mt-1"
  }, [_vm._v("・ 右クリック → コンテキストメニュー")]), _vm._v(" "), _c('div', {
    staticClass: "text-caption ml-4"
  }, [_vm._v("- リンク先QRコードを割り当て")]), _vm._v(" "), _c('div', {
    staticClass: "text-caption ml-4"
  }, [_vm._v("- ギフトQRコードを割り当て")]), _vm._v(" "), _c('div', {
    staticClass: "text-caption ml-4"
  }, [_vm._v("- ロゴ画像を割り当て")]), _vm._v(" "), _c('div', {
    staticClass: "text-caption ml-4"
  }, [_vm._v("- 画像のサイズを指定")]), _vm._v(" "), _c('div', {
    staticClass: "text-caption ml-4"
  }, [_vm._v("- 印刷対象外の設定")]), _vm._v(" "), _c('div', {
    staticClass: "text-body-1 mt-1"
  }, [_vm._v("文字回り込みエリア")]), _vm._v(" "), _c('div', {
    staticClass: "text-caption ml-2"
  }, [_vm._v("\n      ※文字回り込みエリアはテキスト枠が「左寄せ」の場合のみ有効です\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "text-body-2 mt-1"
  }, [_vm._v("・ 右クリック → コンテキストメニュー")]), _vm._v(" "), _c('div', {
    staticClass: "text-caption ml-4"
  }, [_vm._v("- 文字回り込みエリアを削除")]), _vm._v(" "), _c('SectionSubTitle', {
    staticClass: "mt-2"
  }, [_vm._v("ボタンの機能")]), _vm._v(" "), _c('div', {
    staticClass: "tw-flex mt-1 ml-2"
  }, [_c('button', {
    staticClass: "tw-btn tw-btn-icon-small tw-btn-icon-info -tw-mr-[1px] tw-border tw-border-solid tw-border-border-input disabled:tw-border-border tw-rounded-s-md tw-rounded-e-none",
    attrs: {
      "type": "button"
    }
  }, [_c('mdi-format-align-left', {
    staticClass: "tw-size-4"
  })], 1), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-icon-small tw-btn-icon-info tw-border tw-border-solid tw-border-border-input disabled:tw-border-border tw-rounded-none",
    attrs: {
      "type": "button"
    }
  }, [_c('mdi-format-align-center', {
    staticClass: "tw-size-4"
  })], 1), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-icon-small tw-btn-icon-info -tw-ml-[1px] tw-border tw-border-solid tw-border-border-input disabled:tw-border-border tw-rounded-s-none tw-rounded-e-md",
    attrs: {
      "type": "button"
    }
  }, [_c('mdi-format-align-right', {
    staticClass: "tw-size-4"
  })], 1)]), _vm._v(" "), _c('div', {
    staticClass: "text-caption ml-2"
  }, [_vm._v("左寄せ | 中央寄せ | 右寄せ")]), _vm._v(" "), _c('mdi-lock-open-outline', {
    staticClass: "mt-1 ml-2 tw-size-6"
  }), _vm._v(" "), _c('div', {
    staticClass: "text-caption ml-2"
  }, [_vm._v("固定文言にする")]), _vm._v(" "), _c('mdi-robot-dead-outline', {
    staticClass: "mt-1 ml-2 tw-size-6"
  }), _vm._v(" "), _c('div', {
    staticClass: "text-caption ml-2"
  }, [_vm._v("AIによる校正可能にする")]), _vm._v(" "), _c('div', {
    staticClass: "tw-flex mt-1 ml-2"
  }, [_c('button', {
    staticClass: "tw-btn tw-btn-icon-small tw-btn-icon-info -tw-mr-[1px] tw-border tw-border-solid tw-border-border-input disabled:tw-border-border tw-rounded-s-md tw-rounded-e-none",
    attrs: {
      "type": "button"
    }
  }, [_c('mdi-arrow-expand', {
    staticClass: "tw-size-4 tw-rotate-45"
  })], 1), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-icon-small tw-btn-icon-info tw-border tw-border-solid tw-border-border-input disabled:tw-border-border tw-rounded-none",
    attrs: {
      "type": "button"
    }
  }, [_c('mdi-arrow-expand', {
    staticClass: "tw-size-4 tw-rotate-135"
  })], 1), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-icon-small tw-btn-icon-info -tw-ml-[1px] tw-border tw-border-solid tw-border-border-input disabled:tw-border-border tw-rounded-s-none tw-rounded-e-md",
    attrs: {
      "type": "button"
    }
  }, [_c('mdi-arrow-expand-all', {
    staticClass: "tw-size-4 tw-rotate-45"
  })], 1)]), _vm._v(" "), _c('div', {
    staticClass: "text-caption ml-2"
  }, [_vm._v("\n      枠に幅をfit | 枠に高さをfit | 枠に収まるように\n    ")]), _vm._v(" "), _c('SectionSubTitle', {
    staticClass: "mt-2"
  }, [_vm._v("表示について")]), _vm._v(" "), _c('div', {
    staticClass: "text-caption"
  }, [_vm._v("\n      枠内のテキストは参考程度です。折返しの表示が適当なので、実際の表示はプレビューで確認してください。\n    ")])], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };