/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./editor.vue?vue&type=template&id=0f8a36d5&scoped=true"
import script from "./editor.vue?vue&type=script&lang=ts&setup=true"
export * from "./editor.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./editor.vue?vue&type=style&index=0&id=0f8a36d5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f8a36d5",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TextButton: require('/code/src/components/button/TextButton.vue').default,FileDropArea: require('/code/src/components/input/FileDropArea.vue').default,ContextMenu: require('/code/src/components/menu/ContextMenu.vue').default,SectionSubTitle: require('/code/src/components/text/SectionSubTitle.vue').default,SplitView: require('/code/src/components/SplitView.vue').default,TouchTextViewer: require('/code/src/components/widgets/TouchTextViewer.vue').default})
